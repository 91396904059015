import { Layout,Space, Divider,Row,Col,TimePicker } from 'antd';
import Header from '../components/header.js'
import Footer from '../components/footer.js';
import Typography from 'antd/es/typography/Typography.js';
import VesselDetails from '../components/vesselDetailsCard.js'
import DealerDetails from '../components/dealerDetailsCard.js';
import DateTimePortDetails from '../components/dateTimePortDetailsCard.js'
import CollectionDetailsCard from '../components/collectionDetailsCard.js'
import ContactDetailsCard from '../components/contactDetailsCard.js'
import { useNavigate,useLocation } from "react-router-dom";
import {PostalCodeRequired} from '../utils/shared'
import { countries } from '../utils/countries.js';
import { Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import { useState,useEffect } from 'react';
import { returnFormattedAddressMapsAPI } from '../utils/shared';
import { SolutionOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'

const { Title } = Typography;
const {Sider, Content} = Layout
const contentStyle = {
  lineHeight: '60px',
  color: '#fff',
  minHeight: "calc(100vh - 120px)",
};
const siderStyle = {
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#f5f5f5',
  marginTop: 30, 
  marginLeft:10
};


export default function CollectionDetails() {
  
  const {state} = useLocation();
  const [postCodeReq, setPostCodeReq] = useState(false)
  const [collectionRequired, setCollectionRequired] = useState()
  const [collectionEarly, setCollectionEarly] = useState(dayjs())
  const [collectionLate, setCollectionLate] = useState(dayjs().set('hour', 17).set('minute', 0))
  const [form] = Form.useForm();
  const bunkerPort = Form.useWatch('bunker_port', form);
  const collectionDate = Form.useWatch('collection_date', form);
  const addressLineOne = Form.useWatch('address_line_one', form);
  const city = Form.useWatch('city', form);
  const postcode = Form.useWatch('postcode', form);
  const country = Form.useWatch('country', form);
  const companyName = Form.useWatch('company_name', form);
  const contactPerson = Form.useWatch('contact_name', form);
  const contactPhone = Form.useWatch('contact_phone', form);
  const contactEmail = Form.useWatch('contact_email', form);
  const [formValues, setFormValues] = useState()
  const [showForm, setShowForm] = useState(false)
  const navigate = useNavigate();
  const onSwitchChange = (checked) => {
    setCollectionRequired(checked)
  };
  const onFinish = (values) => {
    
    if (collectionRequired){
      var collection = {collection_earliest: collectionEarly, collection_latest: collectionLate, collection_date: collectionDate}
      delete values['collection_date']
      values.collection = collection
      sessionStorage.setItem("address",JSON.stringify(values))
    } else {
      sessionStorage.setItem("address", JSON.stringify(values))
    }
    
   navigate('/confirm-collection', { state:{noParcel: state.noParcel, parcel: state.parcel, parcelNumbers: state.parcelNumbers, collectionDetails: values, vesselValidated: true, showVessel: state.showVessel, postCodeReq:postCodeReq,collectionRequired:collectionRequired, collectionEarly:collectionEarly, collectionLate: collectionLate} })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

   
  // working on setting address based on geocode example
  
  

  useEffect(() => {
    async function error() {
      console.log("Unable to retrieve your location");
    }
    async function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAFcvBvnvSuJODbBydu9JLRLtBqNOOCZ30&language=en`).then(res => {return res.json()}).then(add => {if (add.status === 'OK'){
        return returnFormattedAddressMapsAPI(add.results[0])
      }}).then(address => {
        const fields = form.getFieldsValue()
        fields['address_line_one'] = address.address_line_one
        fields['country'] = address.country
        fields['city'] = address.city
        fields['postcode'] = address.postcode
        form.setFieldsValue(fields)
      })
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
    if (sessionStorage.getItem('address') !== null){
      var formVals = JSON.parse(sessionStorage.getItem('address'))
      if (formVals.hasOwnProperty('collection')){
        formVals.collection_date = dayjs(formVals.collection.collection_date)
      } 
      setFormValues(formVals)
      setShowForm(true)
    } else {
      setFormValues({collection_date: dayjs()})
      setShowForm(true)
    }
  },[form])
  const formItemLayout = {
    labelCol: {
      span: 10
    },
    wrapperCol: {
      span: 24
    }
  };
  console.log(postCodeReq)
    return (        
    <Layout>
        <Header />
        <Layout style={contentStyle} hasSider>
        <Sider width={400} style={siderStyle}>
        <Space direction="vertical" style={{ display: 'flex' }}>
        {/* {(parcelType && parcelType === 'vessel' ? <VesselDetails vessel={vessel} imo={imo} parcelType={parcelType} data={parcelRes} show={show} />:null)}{(parcelType && parcelType === 'dealer' ? <DealerDetails dealer={vessel} dealerId={imo} parcelType={parcelType} data={parcelRes} show={show} />:null)} */}
        {(state.noParcel ? <><VesselDetails registerPackage={true} parcelType={'vessel'} data={state.parcel} show={state.showVessel} /><DateTimePortDetails parcelType={'vessel  '} bunkerPort={bunkerPort}  collectionDate={collectionDate} collectionEarly={collectionEarly} collectionLate={collectionLate} collectionRequired={collectionRequired}/></> :
        (state.parcel.parcel.owner === 'vessel' ? <><VesselDetails parcelNumbers={state.parcelNumbers} data={state.parcel} show={state.showVessel} vesselValidated={true} /> </>: <><DealerDetails  parcelNumbers={state.parcelNumbers} data={state.parcel} dealer={state.parcel.dealer.name} dealerId={state.parcel.dealer.dealerId} show={state.showVessel} /></>)     
        )}
        <ContactDetailsCard contactPerson={contactPerson} contactPhone={contactPhone} contactEmail={contactEmail} companyName={companyName} />
        <CollectionDetailsCard addressLineOne={addressLineOne} city={city} postcode={postcode} postCodeReq={postCodeReq} country={country} />
        </Space>
        </Sider>
        <Content>
            {(showForm ? 
              <Form 
              {...formItemLayout}
              form={form}
              onFinish={onFinish} 
              onFinishFailed={onFinishFailed}
              initialValues={formValues}  
              style={{
                maxWidth: 1200,
              }}         
              >
                <Title  style={{marginLeft: 30}} level={3}> <SolutionOutlined  style={{color:'black', margin:5}}/> Enter Collection Details</Title>
                <Divider />
                <Row><Col span={12}><Form.Item label="Toggle for Collection"><Switch checkedChildren="Collection Required" unCheckedChildren="Collection Not Required" checked={collectionRequired} onChange={onSwitchChange}  /></Form.Item></Col></Row>
                
                
                  {(collectionRequired ? 
                    <>                    
                    <Row>
                        <Col span={12}><Form.Item rules={[ { required: true, message: 'Collection Date is required' }, ]} name='collection_date' label="Collection Date"><DatePicker style={{width:300}} format={"dddd DD MMMM YYYY"} /></Form.Item></Col>
                        </Row>
                        <Row>
                        <Col span={12}><Form.Item rules={[ { required: true, message: 'Earliest Collection Time is required' }, ]} label="Earliest Collection Time" > <TimePicker  value={collectionEarly} onChange={setCollectionEarly} format="HH:mm"/> </Form.Item></Col>
                        <Col span={12}><Form.Item rules={[ { required: true, message: 'Latest Collection Time is required' }, ]} label="Latest Collection Time" > <TimePicker value={collectionLate} onChange={setCollectionLate} format="HH:mm"/> </Form.Item></Col>
                    </Row>
                    </>

                  : null )}
           {(state.parcel.vessel ? <Col span={12 }> <Form.Item name='bunker_port' label="Bunker Port"> <Input  placeholder="..." /> </Form.Item> </Col>: null )}
            <Row>
                <Col span={12}>
                <Form.Item rules={[ { required: true, max: 100, message: 'Company Name is required' }, ]}  name="company_name"  label="Company Name">
                  <Input placeholder="..."/>
                </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item rules={[ { required: true, max: 100, message: 'Name is required' }, ]}  name="contact_name"  label="Contact Name">
                      <Input placeholder="..."/>
                  </Form.Item>
                </Col>
            </Row>
            <Row>
            <Col span={12}>
              <Form.Item rules={[ { required: true, max: 100, message: 'Email is required' }, ]}  name="contact_email"  label="Contact Email">
                  <Input placeholder="..."/>
              </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item rules={[ { required: true, max: 100, message: 'Phone Number is required' }, ]}  name="contact_phone"  label="Contact Phone">
                  <Input placeholder="..."/>
              </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="vat_number"  label="VAT Number">
                    <Input placeholder="..."/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="tax_id"  label="Tax ID">
                    <Input placeholder="..."/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col span={12}>
              <Form.Item name="eori"  label="EORI Number">
                  <Input placeholder="..."/>
              </Form.Item>
            </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <Form.Item rules={[ { required: true, max: 100, message: 'First Address Line is required' }, ]}  name="address_line_one"  label="Address Line One">
                    <Input placeholder="..."/>
                </Form.Item>
                </Col>
              <Col span={12}>
                <Form.Item rules={[ { max: 100 }, ]}  name="address_line_two"  label="Address Line Two">
                    <Input placeholder="..."/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item rules={[ { max: 100 }, ]}  name="address_line_three"  label="Address Line Three">
                    <Input placeholder="..."/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item rules={[ { max: 50 }, ]}  name="state"  label="County/State">
                    <Input placeholder="..."/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
            <Form.Item rules={[ { required: true, max: 50, message: 'Town/City is required' }, ]}  name="city"  label="Town/City">
                <Input placeholder="..."/>
            </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item rules={[ { required: true, message: 'Country is required' }, ]} name="country" label="Country">
                   <Select
                   showSearch
                    defaultValue="Please select Country"
                    options={countries}
                    onChange={(e => {setPostCodeReq(PostalCodeRequired(e))})}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                    
                  />
                </Form.Item>
                </Col>
              </Row>
              <Row>
              <Col span={12}>
              <Form.Item hidden={postCodeReq} rules={[ {required:!postCodeReq,  max: 15 }, ]}  name="postcode"  label="Postal/Zip Code">
                  <Input placeholder="..."/>
              </Form.Item>
              </Col>
            </Row>
            {(!state.parcel.multiple_commodities ? 
              null
            :  
              <Row>
              <Col span={12}>
              <Form.Item  name="commodity"  label="Commodity">
                  <Select  options={state.parcel.multiple_commodities} />
              </Form.Item>
              </Col>
            </Row>
            )}
            <Divider />
              <Row>
                <Col span={24} align='end' style={{margin:5}  }>
                <Form.Item>
                <Button type="primary" htmlType="submit">Next</Button>
                </Form.Item>
                </Col>
              </Row>
              </Form>: null )}
          
          </Content>
        </Layout>
        <Footer />
    </Layout>
    );
  }