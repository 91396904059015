import { Layout,Space, Divider,Row,Col, Button,Spin,Alert,notification,Checkbox,Form } from 'antd';
import Header from '../components/header.js'
import Footer from '../components/footer.js';
import Typography from 'antd/es/typography/Typography.js';
import VesselDetails from '../components/vesselDetailsCard.js'
import DateTimePortDetails from '../components/dateTimePortDetailsCard.js'
import CollectionDetailsCard from '../components/collectionDetailsCard.js'
import ContactDetailsCard from '../components/contactDetailsCard.js'
import { RocketFilled,RedoOutlined,EditOutlined } from '@ant-design/icons';
import { useState,useEffect } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { countries } from '../utils/countries.js';
import DealerDetails from '../components/dealerDetailsCard.js';

import dayjs from 'dayjs'

const { Title,Paragraph } = Typography;
const {Sider, Content} = Layout
const contentStyle = {
  lineHeight: '60px',
  color: '#fff',
  minHeight: "calc(100vh - 120px)"
};
const siderStyle = {
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#f5f5f5',
  marginTop: 30, 
  marginLeft:10
};



export default function ConfirmCollection(props) {
  console.log("THIS IS PROPS")
  const [api, contextHolder] = notification.useNotification();
  const {state} = useLocation();
  const [load, setLoad] = useState(false)
  const [err,setErr] = useState()
  const [emailNotification, setEmailNotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const collectionD = state.collectionDetails.collection
  const navigate = useNavigate();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: errorMessage.titleError,
      description: errorMessage.errorMessage,
      placement: 'bottomRight'
    });
  };
  useEffect(() => {
  },[])

  console.log(state)

//working with vessels registered
//ad commodity-id
  const returnCollectionDetails = (collection,parcel,collectionRequested,noParcel,commodity) => {
    if (collectionRequested){
      var collectionDetails = collection
      collectionDetails.collection_required = true
      collectionDetails.collection_earliest = dayjs(collection.collection_earliest).format("HH:mm")
      collectionDetails.collection_latest = dayjs(collection.collection_latest).format("HH:mm")
      collectionDetails.collection_date = dayjs(collection.collection_date).format("YYYY-MM-DD")
      collectionDetails.collection_timestamp = dayjs(collection.collection_date).format("")
      if (noParcel){
          collectionDetails.shipper = {vessel_name: parcel.vessel.name, vessel_imo: parcel.vessel.imo} 
          collectionDetails.shipper.type = 'vessel'
          collectionDetails.shipper.added_by_user = parcel.vessel.added_by_user
          collectionDetails.non_parcel_number_shipment = true
      } else {
        if (parcel.generic === 'dealer'){
          collectionDetails.shipper = {dealer_name: parcel.dealer.name, dealer_id: parcel.dealer.dealerId} 
          collectionDetails.shipper.type = parcel.generic
          collectionDetails.shipper.added_by_user = parcel.parcel.generic
        } else {
          collectionDetails.shipper = {vessel_name: parcel.vessel.name, vessel_imo: parcel.vessel.imo} 
          collectionDetails.shipper.type = parcel.generic
          collectionDetails.shipper.added_by_user = parcel.parcel.generic
        }
        collectionDetails.commodity_id = commodity
        return collectionDetails
      }
    } else {
      var collectionDetails = {};
      collectionDetails.collection_required = false
      if (noParcel){
        collectionDetails.shipper = {vessel_name: parcel.vessel.name, vessel_imo: parcel.vessel.imo} 
        collectionDetails.shipper.type = 'vessel'
        collectionDetails.shipper.added_by_user = parcel.vessel.added_by_user
        collectionDetails.non_parcel_number_shipment = true
      } else {
        if (parcel.generic === 'dealer'){
          collectionDetails.shipper = {dealer_name: parcel.dealer.name, dealer_id: parcel.dealer.dealerId} 
          collectionDetails.shipper.type = parcel.generic
          collectionDetails.shipper.added_by_user = parcel.parcel.generic
        } else {
          collectionDetails.shipper = {vessel_name: parcel.vessel.name, vessel_imo: parcel.vessel.imo} 
          collectionDetails.shipper.type = parcel.generic
          collectionDetails.shipper.added_by_user = parcel.parcel.generic
        }
      }
      collectionDetails.commodity_id = commodity
      return collectionDetails
    }
   
  }

  const returnAddress = (values) => {
    var address = values
    delete address["name"]
    delete address["client_id"]
    var country = values.country
    var countryDetails = {}
    countries.forEach(c => {
        if (c.value === country){
            countryDetails = {name: c.details.name, code: c.details.code, region: c.details.region}
        }
    })
    address['country_details'] = countryDetails
    return address
  }
  const submitShipment = (e) => {
    setLoad(true)
    var collection = collectionD
    const origin = state.collectionDetails
    state.collectionDetails.system = 'volvo.sampleship.com'
    fetch(`${process.env.REACT_APP_API_URL}/client_create_shipment?no_parcel=${sessionStorage.getItem('noParcel')}&vessel=${sessionStorage.getItem('vesselId')}&imo=${sessionStorage.getItem('vesselImo')}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Parcels": sessionStorage.getItem('parcelNumber'),
        "Domain": 'volvo.sampleship.com'
      },
      credentials: "same-origin",
      body:JSON.stringify({email_notification: emailNotification,shipment: {origin: returnAddress(origin), status: 'Awaiting Pickup', pieces: returnCollectionDetails(collection, state.parcel, state.collectionRequired,state.noParcel, state.collectionDetails.commodity)}})
  })
  .then(res => {
    if(!res.ok){
      setLoad(false)
      openNotificationWithIcon('error')
      res.json().then(r => {setErr(true); setErrorMessage(r)})
      throw Error(res.statusText);
    } else {
      res.json().then(r => {
        if (r.status === 'fail'){
          setErrorMessage({titleError: 'Error!', errorMessage: `${r.error_message} ${r.error_detail}`})
          setErr(true); 
          setLoad(false)
        } else {
          sessionStorage.removeItem("address")
          sessionStorage.removeItem("parcelNumber")
          sessionStorage.removeItem("vesselId")
          sessionStorage.removeItem("vesselImo")
          sessionStorage.removeItem("noParcel")
          navigate('/documents', { state:{parcel: state.parcel, collectionDetails: state.collectionDetails, showVessel: state.showVessel, postCodeReq:state.postCodeReq,collectionRequired:state.collectionRequired, shipment: r} })
        }
      })
    }
   }).catch(error => {
    setErrorMessage({titleError: 'Error!', errorMessage: 'There has been a problem requesting your shipment, please contact support on support@samplelogistics.com.'});setErr(true);
   })
  }

  const onChangeNotification = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setEmailNotification(e.target.checked)
  };

    return (    
        <Spin tip="Creating Shipment..." spinning={load}>    
        <Layout>
        {contextHolder}
        <Header />
        <Layout style={contentStyle} hasSider>
        <Sider width={420} style={siderStyle}>
        <Space direction="vertical" style={{ display: 'flex' }}>
        {(state.noParcel ? <><VesselDetails registerPackage={true} parcelType={'vessel'} data={state.parcel} show={state.showVessel} /></> :
        (state.parcel.parcel.owner === 'vessel' ? <><VesselDetails parcelNumbers={state.parcelNumbers} data={state.parcel} show={state.showVessel} vesselValidated={true} /> </>: <><DealerDetails  parcelNumbers={state.parcelNumbers} data={state.parcel} dealer={state.parcel.dealer.name} dealerId={state.parcel.dealer.dealerId} show={state.showVessel} /></>)     
        )}
        {(state.collectionRequired ? <DateTimePortDetails bunkerPort={state.collectionDetails.bunker_port}  collectionDate={state.collectionDetails.collection.collection_date} collectionEarly={state.collectionEarly} collectionLate={state.collectionLate} collectionRequired={state.collectionRequired}/> : null )}         
        <ContactDetailsCard contactPerson={state.collectionDetails.contact_name} contactPhone={state.collectionDetails.contact_phone} contactEmail={state.collectionDetails.contact_email} companyName={state.collectionDetails.company_name} />
        <CollectionDetailsCard addressLineOne={state.collectionDetails.address_line_one} city={state.collectionDetails.city} postcode={state.collectionDetails.postcode} postCodeReq={state.postCodeReq} country={state.collectionDetails.country} />
        </Space>
        </Sider>
        <Content style={{marginLeft: 30}}>
            <Title level={3}><RocketFilled style={{color:'black', margin:5}}/> Confirm Shipment Details</Title>
            <Divider />
            <Paragraph>Please check the collection information in the panels to the left. If something is wrong click on the button below to and amend your details.</Paragraph><Button onClick={() => {navigate(-1)}} size='medium' icon={<EditOutlined />} type='default'>Edit Collection Details</Button>
            <Paragraph>If all information is correct please click the 'Go' button below. Sample Ship will generate the necessary waybills, commercial invoice and MSDS documentation and book a collection.</Paragraph>
            <Paragraph>The page will refresh and display links to the documentation and the collection reference number.</Paragraph>
           
            {(err ?  
            <Col span={14}>     
              <Alert
                showIcon
                message={errorMessage.titleError}
                description={errorMessage.errorMessage}
                type="error"
              />
               <Row><Col span={1}>
              <Button onClick={() => {navigate(-1)}} size='large' icon={<RedoOutlined />} type='primary'>Try Again</Button>
              </Col></Row>
              </Col>
              : 
              <>
              <Row><Col span={24}>
              <Form.Item label={<strong>Would you like to recieve an email summary of your shipment to {state.collectionDetails.contact_email}?</strong>}><Checkbox value={emailNotification} onChange={onChangeNotification} /></Form.Item>
              </Col>
              </Row>
              <Row>
                <Col span={4}>
              <Button onClick={submitShipment} size='large' icon={<RocketFilled />} type='primary'>Go</Button>
              </Col>
              </Row>
              </>)}
        </Content>
        </Layout>
        <Footer />
    </Layout>
    </Spin>
    );
  }