import { Layout,Row,Col } from "antd";
import Typography from "antd/es/typography/Typography";
const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 50,
    backgroundColor: '#202A44',
    bottom:0,
  };
export default function Footer() {
    return (        
        <Layout style={footerStyle}>
          <Row>
            <Col>
              <Typography style={{marginTop:20, color:'white'}}>© Copyright 2024 Sample Logistics</Typography>
            </Col>
          </Row>
        </Layout>
    );
  }